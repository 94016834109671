<template>
  <b-card
    :header="'Заявка на выплату №'+ userPayout.payoutId"
    header-tag="h4"
  >

    <b-row
      v-if="statusId === 3"
      class="mb-50"
    >

      <b-col><div>
        <b class="text-danger">Причина отказа:</b> {{ comment }}
      </div></b-col>
    </b-row>
    <b-row class="mb-50">
      <b-col><div><b>Бонусов к оплате:</b> {{ bonus }}</div></b-col>
    </b-row>
    <b-row class="mb-50">
      <b-col>
        <div v-if="userPayout.payoutDataType.id === 2 && userPayout.ownerPaymentData">
          <b> Получатель платежа:</b> {{ userPayout.ownerPaymentData }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-50">
      <b-col><div><b>Способ оплаты:</b> {{ userPayout.payoutDataType.title }}</div></b-col>
    </b-row>
    <b-row class="mb-50">
      <b-col v-if="userPayout.payoutDataType.name === 'card'">
        <div><b>Банк получателя:</b> {{ `${definePaymentSystem(userPayout.details)} ${userPayout.bankName || '(Банк получателя не определен)'}` }}</div>
      </b-col>
    </b-row>

    <b-row class="mb-50">
      <b-col class="d-flex align-items-center">

        <div>
          <div
            variant="white"
            class="border-primary d-flex align-items-center pl-1 pr-0 rounded"
            title="копировать в буфер обмена"
            style="height: 40px"
          >

            <div style="cursor: text">
              <b class="mr-50">Номер счета:</b> {{ formatDetailNumber(userPayout.details) || 'нет номера' }}
            </div>
            <b-button
              v-clipboard:copy="userPayout.details"
              v-clipboard:success="onCopy"
              squared
              class="ml-50"
              variant="primary"
            >
              {{ copy }}
            </b-button>

            <feather-icon
              v-if="icon"
              class="text-success"
              :icon="icon"
              size="30"
            />

          </div>
        </div>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'

import VueClipboard from 'vue-clipboard2'
import definePaymentSystem from '@/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  props: {
    userPayout: {
      type: Object,
      required: true,
    },
    bonus: {
      type: Number,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
  },
  data() {
    return { copy: 'копировать', icon: null, definePaymentSystem }
  },
  methods: {
    onCopy() {
      this.icon = 'CheckIcon'
      setTimeout(() => { this.icon = null }, 1000)
    },
    formatDetailNumber(number) {
      if (!number) return null
      let detailNumber = number.replace(/\D/g, '')

      if (String(detailNumber).length === 16) {
        return detailNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
      }

      if (String(detailNumber).length === 11 && /^(8|7)/.test(detailNumber)) {
        detailNumber = detailNumber.slice(1)
      }

      return detailNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
    },
  },
  setup() {
    return { VueClipboard }
  },
}

</script>
