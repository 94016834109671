export default function definePaymentSystem(cardNumber) {
  if (!cardNumber) return ''
  const card = cardNumber.toString().replace(/[^\d]/g, '')

  let result
  const types = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    mir: /^220[0-4][0-9][0-9]\d{10}$/,
  }

  Object.keys(types).forEach(key => {
    if (types[key].test(card)) {
      result = key.toString().toUpperCase()
    }
  })

  return result || ''
}
