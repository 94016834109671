<template>
  <b-sidebar
    id="edit-payout-moderation-user-motivation-sidebar"
    :visible="isPanelPayoutModerationEditActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-panel-payout-moderation-edit-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          модерация выплат пользователя
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        v-slot="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="clearForm"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Статус"
            rules="required|statusValid"
          >
            <b-form-group
              label="Статус"
              label-for="status"
            >
              <v-select
                v-model="elementData.statusId"
                name="Статус"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.id"
                :clearable="false"
              />

              <span class="text-danger">{{ errors[0] }}</span>

            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="elementData.statusId === 3"
            v-slot="{ errors }"
            name="комментарий"
            rules="required"
          >
            <b-form-textarea
              v-model="comment"
              class="mt-1"
              placeholder="Оставьте комментарий"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="checkPermission(elementData.statusId)"
            >
              Изменить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required, statusValid } from '@core/utils/validations/validations'
import { updateElement, getPayoutStatusList } from '@/application/payoutService'
import { resolveUserPayoutMotivationStatus } from '@/utils/UserFilter'
import ability from '@/libs/acl/ability'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,

    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPanelPayoutModerationEditActive',
    event: 'update:is-panel-payout-moderation-edit-active',
  },
  props: {
    isPanelPayoutModerationEditActive: {
      type: Boolean,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
    payoutId: {
      type: Number,
      required: true,
    },
    bonus: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { comment: null }
  },
  methods: {
    onSubmit() {
      if (this.comment) Object.assign(this.elementData, { comment: this.comment })

      this.updateElement(this.payoutId, this.elementData).then(() => {
        this.$emit('update:is-panel-payout-moderation-edit-active', false)
        this.$emit('refetch-data')
        this.$swal({
          icon: 'success',
          title: 'Обработка заявки!',
          text: 'Заявка успешно обработана.',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      })
    },
    checkPermission(status) {
      return ((ability.can('update', 'PayoutManage') && !ability.can('payout', 'PayoutManage')) && (status === 1 || status === 5 || this.statusId === 3))
      || ((ability.can('payout', 'PayoutManage') && !ability.can('update', 'PayoutManage') && (status !== 5 || this.statusId === 3)))
    },
  },
  setup({ bonus, statusId }) {
    const blankData = {
      statusId: statusId || 1,
      bonus,
    }
    const statusOptions = ref([])

    getPayoutStatusList().then(res => {
      statusOptions.value = res
    })

    const elementData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      elementData.value = JSON.parse(JSON.stringify(blankData))
    }

    const {
      refFormObserver,
      clearForm,
    } = formValidation(resetData)

    return {
      required,
      statusValid,

      elementData,
      statusOptions,

      refFormObserver,
      clearForm,

      updateElement,
      resolveUserPayoutMotivationStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
