<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-30">
      <h5 class="mb-0">
        Заявка
      </h5>
      <b-badge variant="light-primary">
        {{ payoutData.id }}
      </b-badge>
      <small class="text-muted w-100">{{ formatDate(payoutData.createdAt) }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>

          <span class="align-middle">
            <b-badge :variant="`light-${ resolvePayoutStatusVarian(payoutData.status.name) }`">
              {{ resolveUserPayoutMotivationStatus(payoutData.status.name) }}
            </b-badge>
          </span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="checkPermission(payoutData.status.id)"
        @click="$emit('openPanel')"
      >
        Изменить статус
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@/libs/helper'
import {
  resolveUserMotivationChanged, verifEmailVariant, resolveUserPayoutMotivationStatus, resolvePayoutStatusVarian,
} from '@/utils/UserFilter'
import ability from '@/libs/acl/ability'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    payoutData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkPermission(status) {
      return !(
        ability.can('manage', 'all') || (ability.can('update', 'PayoutManage') && ability.can('payout', 'PayoutManage'))
      || ((status !== 5 && status !== 3)
      && ((status !== 4 && (ability.can('update', 'PayoutManage') && !ability.can('payout', 'PayoutManage')))
      || (status === 4 && (ability.can('payout', 'PayoutManage') && !ability.can('update', 'PayoutManage')))))
      )
    },
  },
  setup() {
    return {
      formatDate,
      verifEmailVariant,
      resolveUserMotivationChanged,
      resolveUserPayoutMotivationStatus,
      resolvePayoutStatusVarian,

      ability,
    }
  },
}
</script>
