<template>
  <div>
    <panel-payout-moderation-edit
      v-if="payoutData"
      :is-panel-payout-moderation-edit-active.sync="isPanelPayoutModerationEditActive"
      :status-id="(payoutData ? payoutData.status.id : null)"
      :payout-id="payoutData.id"
      :bonus="payoutData.bonus"
      @refetch-data="refetch"
    />

    <b-alert
      v-if="errorData"
      variant="danger"
      :show="!(payoutData && bonuses && userData)"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        {{ errorData }} Проверьте
        <b-link
          class="alert-link"
          :to="{ name: 'moderation-bonuses'}"
        >
          лист модерации
        </b-link>
        для других пользователей.
      </div>
    </b-alert>

    <b-row v-if="userData && bonuses">
      <b-col
        xl="9"
        lg="8"
        md="7"
        class="p-0"
      >
        <user-payout-info-card
          :user-data="userData"
          :bonuses="bonuses"
        />
      </b-col>
      <b-col
        md="5"
        xl="3"
        lg="4"
      >
        <user-view-user-payout-motivation-edit-card
          v-if="payoutData"
          :payout-data="payoutData"
          @openPanel="isPanelPayoutModerationEditActive = true"
        />

      </b-col>
    </b-row>

    <user-payout-info
      v-if="payoutUserData && payoutData &&
        payoutData.status && payoutData.status.id
        && payoutData.bonus"

      :user-payout="payoutUserData"
      :bonus="payoutData.bonus"
      :status-id="payoutData.status.id"
      :comment="payoutData.comment ? payoutData.comment : ''"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BAlert,
} from 'bootstrap-vue'
import { formatDate } from '@/libs/helper'
import { avatarText } from '@core/utils/filter'
import { getElementById } from '@/application/payoutService'
import { getBonusUserList } from '@/application/bonusService'

import { ref } from '@vue/composition-api'

import {
  generateRandomVariant,
  verifEmailVariant,
  verifEmailIcon,
  resolveUserRoleIcon,
  resolveUserRoleVariant,
} from '@/utils/UserFilter'
import UserViewUserPayoutMotivationEditCard from '@/components/user/UserViewUserPayoutMotivationEditCard.vue'
import UserPayoutInfoCard from '@/views/payout/components/UserPayoutInfoCard.vue'
import router from '@/router'
import UserPayoutInfo from './components/UserPayoutInfo.vue'
import PanelPayoutModerationEdit from './components/PanelPayoutModerationEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BAlert,
    UserViewUserPayoutMotivationEditCard,
    UserPayoutInfoCard,
    PanelPayoutModerationEdit,
    UserPayoutInfo,
  },

  setup() {
    const payoutUserData = ref()
    const payoutData = ref()
    const userData = ref()
    const bonuses = ref()
    const errorData = ref(false)
    const isPanelPayoutModerationEditActive = ref(false)

    const { payoutId } = router.currentRoute.params

    const refetch = async () => {
      const { payouts, payout, userPayout } = await getElementById(payoutId)

      payoutUserData.value = payouts
      payoutData.value = payout
      userData.value = userPayout

      if (userPayout?.id) {
        bonuses.value = await getBonusUserList({ userId: userPayout.id })
      }
    }
    refetch()
    return {
      payoutUserData,
      payoutData,
      userData,
      bonuses,

      errorData,
      refetch,

      formatDate,
      avatarText,
      generateRandomVariant,
      verifEmailVariant,
      verifEmailIcon,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      isPanelPayoutModerationEditActive,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.sale-admin .v-select {
  min-width: 350px;
}
.sale-admin .v-select.vs--single .vs__selected {
  width: 90%;
}

.item-info {
  margin-bottom: 10px;
}

</style>
